// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

// .css-3w2yfm-ValueContainer{
//     max-height: inherit;
// }
// .css-13cymwt-control{
//     max-height: 35px;
// }


.sql__container {
    margin-bottom: 20px;
  }
  
  .card-wrapper {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .card-body {
    padding: 20px;
  }
  
  .center {
    text-align: center;
  }
  
  .label-bold {
    font-weight: bold;
  }
  
  .input-large {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
  }
  
  .file-input-container {
    display: flex;
    align-items: center;
  }
  
  .file-input-label {
    flex: 1;
    margin-right: 10px;
  }
  
  .file-input {
    flex: 2;
  }
  
  .button-primary {
    margin-top: 20px;
  }


  .sql__container {
    margin-bottom: 20px;
  }
  
  .card-wrapper {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .card-body {
    padding: 20px;
  }
  
  .center {
    text-align: center;
  }
  
  .font-weight-bold {
    font-weight: bold;
  }
  
  .input-large {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
  }
  
  .file-input-container {
    display: flex;
    align-items: center;
  }
  
  .file-input-label {
    flex: 1;
    margin-right: 10px;
  }
  
  .file-input {
    flex: 2;
  }
  
  .button-primary {
    margin-top: 20px;
  }
  
  /* Additional styles for the remaining cards */
  .card-wrapper-secondary {
    margin-top: 20px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }